import clsx from 'clsx';
import React, { FC, useEffect, useState } from 'react';
import styles from './CreateProvider.module.css';
import { ReactComponent as ArrowTopIcon } from '../icons/ArrowTop.svg';
import { ReactComponent as CloseIcon } from '../icons/Close.svg';
import { ReactComponent as CopyIcon } from '../icons/Copy.svg';
import { ReactComponent as PasteProviderIcon } from '../icons/PasteProvider.svg';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import {
  MiscProviderType,
  TCreateProvider,
  TEsiaParams,
  useCreateProviderMutation,
} from '../redux/services/provider';

import { isObjectEmpty, isOwner } from '../helpers';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import { ProviderHeader } from './ProviderHeader';
import { ProviderFooter } from './ProviderFooter';
import { useParams } from 'react-router-dom-v5-compat';
import { useSelector } from 'react-redux';
import { RootState } from '../redux/rootReducer';
import { ScopeChips } from './ScopeChips';
import { TChips } from './CreateProvider';
import InputAdornment from '@mui/material/InputAdornment';
import { BACKEND_URL } from '../constants';

export type CreateEsiaProviderInputs = {
  name: string;
  description: string;
  avatar: File | null;
  path_to_avatar: string;
  auto_registration?: boolean;
  external_client_id: string;
  sign_endpoint: string;
  verify_endpoint: string;
  is_public: boolean;
};

const schema = yup.object({
  name: yup
    .string()
    .required('Обязательное поле')
    .max(50, 'Название не может превышать 50 символов')
    .matches(/[^ ]+/, {
      message: 'Название не может состоять только из пробелов',
    })
    .matches(/^[^ ]+( *[^ ]+)*?$/, 'Название не может содержать пробелы в начале и конце'),
  description: yup
    .string()
    .max(255, 'Описание не может превышать 255 символов')
    .matches(/^$|[^ ]+/, {
      message: 'Описание не может состоять только из пробелов',
    }),
  sign_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  verify_endpoint: yup
    .string()
    .url('Неверный формат ссылки')
    .max(2000, 'Адрес не может превышать 2000 символов')
    .required('Обязательное поле'),
  auto_registration: yup.boolean(),
  is_public: yup.boolean(),
});

export const CreateEsiaProvider: FC<TCreateProvider> = ({ isOpen, close, pathToAvatar, scope }) => {
  const methods = useForm<CreateEsiaProviderInputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      description: '',
      auto_registration: false,
      is_public: false,
      path_to_avatar: pathToAvatar,
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, dirtyFields },
    setError,
    clearErrors,
    reset,
  } = methods;

  const [saveModalOpen, setSaveModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const { clientId = '' } = useParams<{ clientId: string }>();
  const [overrideImage, setOverrideImage] = useState<File | string | null>(null);
  const watchDescription = watch('description');
  const [createProvider, createResult] = useCreateProviderMutation();
  const [avatarSrc, setAvatarSrc] = useState<string | null>(null);
  const userRole = useSelector((state: RootState) => state.user.userProfile.role);
  const [chips, setChips] = useState<TChips[]>([]);

  useEffect(() => {
    if (createResult.isSuccess) close(true);
  }, [createResult]);

  useEffect(() => {
    return () => {
      reset();
      setOverrideImage(null);
    };
  }, [isOpen]);

  const closeSaveModal = () => setSaveModalOpen(false);
  const closeClipboardModal = () => setClipboardModalOpen(false);
  const setAvatarValue = (value: File | null) => setValue('avatar', value, { shouldDirty: true });
  const setAvatarLink = (value: string) => {
    setValue('path_to_avatar', value, { shouldDirty: true });
  };
  const setAvatarError = (error: string) => setError('avatar', { message: error });
  const clearAvatarError = () => clearErrors('avatar');

  const handleClose = () => {
    if (isObjectEmpty(dirtyFields)) close();
    else setSaveModalOpen(true);
  };

  const setFields = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const provider: Partial<
        Omit<CreateEsiaProviderInputs, 'avatar'> & {
          id: string;
          isPublic: boolean;
          client_id: string;
          params: TEsiaParams;
          avatar: string;
          type: MiscProviderType.ESIA;
        }
      > = JSON.parse(text);
      const { type, avatar, params, ...restInputs } = provider || {};
      delete restInputs.id;
      delete restInputs.client_id;
      if (type !== MiscProviderType.ESIA) {
        setClipboardModalOpen(true);
      } else {
        if (avatar) {
          setOverrideImage(avatar);
          setValue('avatar', null);
          setValue('path_to_avatar', avatar, { shouldDirty: !provider });
        }
        if (params) {
          (
            Object.keys(params) as Array<
              keyof Omit<
                TEsiaParams,
                | 'authorization_endpoint'
                | 'token_endpoint'
                | 'issuer'
                | 'userinfo_endpoint'
                | 'mapping'
                | 'scopes'
              >
            >
          ).forEach((field) => {
            setValue(field, params?.[field] || '', { shouldDirty: !provider });
          });
        }
        if (restInputs) {
          (
            Object.keys(restInputs) as Array<keyof Omit<CreateEsiaProviderInputs, 'avatar'>>
          ).forEach((field) => {
            if (field === 'auto_registration' || field === 'is_public') {
              return setValue(field, restInputs?.[field] === true, { shouldDirty: !provider });
            }
            setValue(field, restInputs?.[field] || '', { shouldDirty: true });
          });
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit: SubmitHandler<CreateEsiaProviderInputs> = (data) => {
    const { avatar, ...rest } = data;
    if (!isOwner(userRole)) {
      delete rest.auto_registration;
    }
    createProvider({
      body: {
        type: MiscProviderType.ESIA,
        ...rest,
        avatar: avatar ? avatar : null,
        is_active: true,
      },
      client_id: clientId,
    });
  };

  return (
    <Drawer
      classes={{ paper: styles['drawer-paper'] }}
      BackdropProps={{ className: styles.backdrop }}
      onClose={handleClose}
      open={isOpen}
      anchor="right"
      variant="temporary"
    >
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)} className={styles.wrapper}>
          <div className={styles.header}>
            <IconButton onClick={handleClose} className={styles['button-back']}>
              <ArrowTopIcon className={styles['arrow-icon']} />
            </IconButton>
            <Typography className={clsx('text-24-medium', 'font-golos')}>
              Создать способ входа ЕСИА
            </Typography>
            <Tooltip
              arrow
              title={'Вставить настройки'}
              classes={{
                tooltip: styles['input-tooltip'],
                arrow: styles['input-tooltip-arrow'],
              }}
            >
              <IconButton
                onClick={() => {
                  setFields();
                }}
                className={styles['paste-provider-button']}
              >
                <PasteProviderIcon />
              </IconButton>
            </Tooltip>
            <IconButton className={styles['close-button']} onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </div>
          <div className={styles['create-provider-form']}>
            <ProviderHeader
              type={scope}
              watchDescription={watchDescription}
              overrideImage={overrideImage}
              setAvatarError={setAvatarError}
              clearAvatarError={clearAvatarError}
              setAvatarValue={setAvatarValue}
              setAvatarLink={setAvatarLink}
              imgSrc={avatarSrc}
              setImgSrc={setAvatarSrc}
              pathToAvatar={pathToAvatar}
            />
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Идентификатор ресурса (client_id)
            </Typography>
            <TextField
              {...register('external_client_id', {
                required: true,
                onChange: () => {
                  if (errors.external_client_id) clearErrors('external_client_id');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.external_client_id}
              helperText={errors.external_client_id ? errors.external_client_id.message : ''}
              fullWidth
              variant="standard"
              autoComplete="off"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Уникальный идентификатор подключаемого ресурса
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Redirect URI
            </Typography>
            <TextField
              value={BACKEND_URL + '/api/interaction/code'}
              disabled
              className="custom"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Tooltip
                      arrow
                      title="Копировать"
                      classes={{
                        tooltip: styles['input-tooltip'],
                        arrow: styles['input-tooltip-arrow'],
                      }}
                    >
                      <Button
                        className={styles['input-adornment-button']}
                        onClick={() => {
                          navigator.clipboard.writeText(BACKEND_URL + '/api/interaction/code');
                        }}
                      >
                        <CopyIcon />
                      </Button>
                    </Tooltip>
                  </InputAdornment>
                ),
                classes: {
                  disabled: styles['input-wrapper-disabled'],
                },
              }}
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Cсылка должна быть указана в настройках внешних способов входа для корректной
              аутентификации пользователя
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Адрес получения подписи запроса (sign_endpoint)
            </Typography>
            <TextField
              {...register('sign_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.sign_endpoint) clearErrors('sign_endpoint');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.sign_endpoint}
              helperText={errors.sign_endpoint ? errors.sign_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Ресурс, который подписывает запрос
            </Typography>
            <Typography
              className={clsx('text-14', 'color-0B1641', styles.asterisk, styles['input-title'])}
            >
              Адрес проверки подписи запроса (verify_endpoint)
            </Typography>
            <TextField
              {...register('verify_endpoint', {
                required: true,
                onChange: () => {
                  if (errors.verify_endpoint) clearErrors('verify_endpoint');
                },
              })}
              className="custom"
              FormHelperTextProps={{
                className: clsx('text-14', 'color-858BA0'),
              }}
              error={!!errors.verify_endpoint}
              helperText={errors.verify_endpoint ? errors.verify_endpoint.message : ''}
              fullWidth
              variant="standard"
            />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Ресурс, который проверяет подпись ответа от ЕСИА
            </Typography>
            <Typography className={clsx('text-14', 'color-0B1641', styles['input-title'])}>
              Запрашиваемые разрешения
            </Typography>
            <ScopeChips chips={chips} setChips={setChips} />
            <Typography className={clsx('text-14', 'color-858BA0', styles['input-subtitle'])}>
              Перечень разрешений, которые должны быть получены при обращении к поставщику
              идентификации. Для добавления разрешения введите его имя и нажмите Enter.
            </Typography>
            <ProviderFooter type={MiscProviderType.ESIA} clientId={clientId} />
          </div>
          <div
            className={styles.divider}
            style={{ marginBottom: 0, width: '100%', marginLeft: 0 }}
          />
          <div className={styles['bottom-buttons']}>
            <Button onClick={handleClose} variant="custom" color="secondary">
              Отмена
            </Button>
            <Button
              style={{ marginLeft: 24 }}
              disabled={createResult.isLoading}
              variant="custom"
              type="submit"
            >
              Создать
            </Button>
          </div>
        </form>
      </FormProvider>
      <Modal open={saveModalOpen} onClose={closeSaveModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Сохранение изменений
            </Typography>
            <IconButton onClick={closeSaveModal} style={{ marginLeft: 'auto', marginBottom: 16 }}>
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Изменения не сохранены. Продолжить без сохранения?
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="custom" color="secondary" onClick={closeSaveModal}>
              Отмена
            </Button>
            <Button
              onClick={() => {
                close();
                setSaveModalOpen(false);
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Продолжить
            </Button>
          </div>
        </div>
      </Modal>
      <Modal open={clipboardModalOpen} onClose={closeClipboardModal}>
        <div className={styles['save-modal']}>
          <div style={{ display: 'flex' }}>
            <Typography className={clsx('header-2-medium', 'font-golos', 'color-0B1641')}>
              Вставить настройки
            </Typography>
            <IconButton
              onClick={closeClipboardModal}
              style={{ marginLeft: 'auto', marginBottom: 16 }}
            >
              <CloseIcon />
            </IconButton>
          </div>
          <Typography style={{ marginBottom: 32 }} className={clsx('text-14', 'color-0B1641')}>
            Скопированные настройки не подходят для ЕСИА-провайдера.
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Button
              onClick={() => {
                closeClipboardModal();
              }}
              variant="custom"
              style={{ marginLeft: 24 }}
            >
              Закрыть
            </Button>
          </div>
        </div>
      </Modal>
    </Drawer>
  );
};
