import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import { TUserSlice } from '../../redux/userSlice';
import React, { FC, useState } from 'react';
import { connect } from 'react-redux';
import { RootState } from '../../redux/store';
import styles from './UserProfile.module.css';
import { ReactComponent as ArrowDownIcon } from '../../icons/ArrowDown.svg';
import { ReactComponent as AvatarIcon } from '../../icons/Avatar.svg';
import {
  exportToJson,
  formatDate,
  formatPhoneNumber,
  getClaimPrivacy,
  getImageURL,
} from '../../helpers';
import {
  AccountTypes,
  useGetExternalAccountsQuery,
  useGetPrivateClaimsQuery,
} from '../../redux/services/user';
import { DATA_PROCESSING_POLICY_URL } from '../../constants';
import { PublicStatusPopover } from './PublicStatusPopover';
import { useLazyDeleteAllSessionQuery } from '../../redux/services/auth';
import { ExternalAccount } from './ExternalAccount';
import { Link, useNavigate } from 'react-router-dom-v5-compat';
import { ChangePasswordBlock } from './ChangePasswordBlock';
import { PublicProfileBlock } from './PublicProfileBlock';
import { PublicProfile } from './PublicProfile';
import { useGetProfileFieldsQuery } from '../../redux/services/settings';
import { Roles } from '../../enums';

const mapStateToProps = ({ user }: RootState) => ({
  userProfile: user.userProfile,
});

type TUserProfileComponent = {
  userProfile: TUserSlice['userProfile'];
};

const UserProfileComponent: FC<TUserProfileComponent> = ({ userProfile }) => {
  const date = userProfile.birthdate ? new Date(userProfile.birthdate) : null;
  const passwordUpdateDate = new Date(userProfile.password_updated_at || '');
  const navigate = useNavigate();
  const { data: externalAccounts } = useGetExternalAccountsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const { data: profileFields } = useGetProfileFieldsQuery();
  const { data: privateClaims } = useGetPrivateClaimsQuery(String(userProfile.id), {
    skip: !userProfile.id,
  });
  const {
    public_profile_claims_oauth,
    public_profile_claims_gravatar,
    public_accounts_claims_oauth,
    public_accounts_claims_gravatar,
  } = privateClaims || {};
  const [deleteAllSession] = useLazyDeleteAllSessionQuery();

  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const handleOpenDrawer = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const cusomFields =
    profileFields?.filter((field) => field.type === 'custom' && field.active) || [];
  const lineCustomFields = cusomFields.map((field) => {
    return (
      <div key={field.field} className={styles['info-item']}>
        <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
          {field.title}
        </Typography>
        <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
          {userProfile.custom_fields && userProfile.custom_fields[field.field]
            ? userProfile.custom_fields[field.field]
            : 'Не задано'}
        </Typography>
        <PublicStatusPopover
          claimPrivacy={getClaimPrivacy(
            field.field,
            public_profile_claims_oauth,
            public_profile_claims_gravatar,
          )}
          claims={field.field}
          userId={userProfile.id}
        />
      </div>
    );
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <Typography
          style={{ marginBottom: 32 }}
          className={clsx('text-24-medium', 'font-golos', 'color-0B1641')}
        >
          Профиль
        </Typography>
        <Typography style={{ marginBottom: 14 }} className={clsx('text-14', 'color-858BA0')}>
          Вы можете управлять доступом к вашему аккаунту. Настройте какие данные будут предоставлены
          приложениям после вашего согласия.
        </Typography>
        <div className={styles.panel}>
          <div className={styles['panel-title']}>
            <Typography className={clsx('header-3', 'font-golos')}>Основная информация</Typography>
            {userProfile.role !== Roles.TRUSTED_USER && (
              <Link
                to="/profile/edit"
                className={styles['margin-right']}
                style={{ marginLeft: 'auto', textDecoration: 'none' }}
              >
                <Button variant="custom2">Изменить</Button>
              </Link>
            )}
          </div>
          <div className={styles.info}>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  ID пользователя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.id}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'id',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="id"
                disabled
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Публичное имя
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.nickname ? userProfile.nickname : 'Нет имени'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'nickname',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="nickname"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                Фото профиля
              </Typography>
              {userProfile.picture ? (
                <div
                  style={{
                    backgroundImage: `url(${getImageURL(userProfile?.picture)})`,
                  }}
                  className={styles['user-icon-wrapper']}
                />
              ) : (
                <Avatar className={styles.avatar}>
                  <AvatarIcon />
                </Avatar>
              )}
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'picture',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="picture"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Имя и фамилия
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {(
                    (userProfile.given_name || '') +
                    ' ' +
                    (userProfile.family_name || '')
                  ).trim() || 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'family_name',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="family_name given_name"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Логин
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.login || 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'login',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="login"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Дата рождения
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {date ? formatDate(date) : 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'birthdate',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="birthdate"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Электронная почта
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.email ? userProfile.email : 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'email',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="email"
                userId={userProfile.id}
              />
            </div>
            <div className={styles['info-item']}>
              <div className={styles['flex-wrap']}>
                <Typography className={clsx('text-14', 'color-858BA0', styles['info-item-title'])}>
                  Номер телефона
                </Typography>
                <Typography className={clsx('text-14', 'color-0B1641', styles['info-item-value'])}>
                  {userProfile.phone_number
                    ? formatPhoneNumber(userProfile.phone_number)
                    : 'Не задано'}
                </Typography>
              </div>
              <PublicStatusPopover
                claimPrivacy={getClaimPrivacy(
                  'phone_number',
                  public_profile_claims_oauth,
                  public_profile_claims_gravatar,
                )}
                claims="phone_number"
                userId={userProfile.id}
              />
            </div>
            {lineCustomFields.length > 0 && (
              <div className={styles['panel-title']}>
                <Typography className={clsx('header-3', 'font-golos')}>
                  Дополнительная информация
                </Typography>
              </div>
            )}
            {lineCustomFields}
          </div>
        </div>
        <div className={styles.panel}>
          <div className={styles.justify}>
            <Typography className={clsx('text-17-regular', 'font-golos')}>Способы входа</Typography>
            {userProfile.role !== Roles.TRUSTED_USER && (
              <Button
                variant="custom2"
                className={styles['add-button']}
                onClick={() => navigate('/profile/external-provider')}
              >
                Добавить
              </Button>
            )}
          </div>
          <div className={styles.info}>
            {externalAccounts
              ?.filter((ea) => ea.type !== AccountTypes.EMAIL && ea.type !== AccountTypes.PHONE)
              .map((account) => (
                <ExternalAccount
                  account={account}
                  userProfile={userProfile}
                  public_accounts_claims_oauth={public_accounts_claims_oauth}
                  public_accounts_claims_gravatar={public_accounts_claims_gravatar}
                  key={
                    (account.sub || '') +
                    (account.issuer || '') +
                    (account.type || '') +
                    (account.email || '')
                  }
                  withoutButtons={
                    account.type === AccountTypes.ALDPRO ||
                    account.type === AccountTypes.IDM ||
                    account.type === AccountTypes.LDAP ||
                    account.type === AccountTypes._1C
                      ? true
                      : false
                  }
                />
              ))}
          </div>
        </div>
        <ChangePasswordBlock
          passwordUpdateDate={passwordUpdateDate}
          navigateTo="/profile/change-password"
        />
        <PublicProfileBlock onOpenDrawer={handleOpenDrawer} />
        <PublicProfile
          userEmail={userProfile.email || ''}
          isOpen={isDrawerOpen}
          close={handleCloseDrawer}
        />
        <Accordion className={clsx(styles.panel, styles.accordion)}>
          <AccordionSummary
            className={styles['accorion-summary']}
            classes={{ content: styles['accorion-summary-content'] }}
            expandIcon={<ArrowDownIcon fill="#0B1641" />}
          >
            <Typography className={clsx('text-17-regular', 'font-golos')}>
              Другие действия
            </Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            {/* #293
            <Button 
              variant="custom2"
              style={{ textAlign: 'left' }}
              className={styles['margin-bottom']}
            >
              {isAdministrator(userProfile.role)
                ? 'Отказаться от прав администратора'
                : 'Получить права администратора'}
            </Button> */}
            <Button
              variant="custom2"
              className={styles['margin-bottom']}
              onClick={async () => {
                await deleteAllSession(userProfile.id);
                window.location.reload();
              }}
            >
              Выйти со всех устройств
            </Button>
            <a href={DATA_PROCESSING_POLICY_URL}>
              <Button variant="custom2" className={styles['margin-bottom']}>
                Политика обработки ПДн
              </Button>
            </a>
            <Button
              onClick={() => exportToJson(userProfile, 'profile.json')}
              variant="custom2"
              className={styles['margin-bottom']}
            >
              Скачать данные
            </Button>
            {userProfile.id && parseInt(userProfile.id, 10) !== 1 && (
              <Button
                onClick={() => navigate('/profile/delete')}
                variant="custom2"
                className={styles['margin-bottom']}
              >
                Удалить аккаунт
              </Button>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
};

export const UserProfile = connect(mapStateToProps)(UserProfileComponent);
